/* eslint-disable */
import { lazy } from 'react';
import { USER_ROLE } from 'constants.js';
import { DEFAULT_PATHS } from 'config.js';

const dashboards = {
  elearning: lazy(() => import('views/dashboards/ElearningDashboard')),
  // school: lazy(() => import('views/dashboards/SchoolDashboard')),
  // student: lazy(() => import('views/dashboards/StudentDashboard')),
};
const courses = {
  explore: lazy(() => import('views/courses/CoursesExplore')),
   list: lazy(() => import('views/courses/CoursesList')),
  detail: lazy(() => import('views/courses/CoursesDetail')),
};
// const quiz = {
//   list: lazy(() => import('views/quiz/QuizList')),
//   detail: lazy(() => import('views/quiz/QuizDetail')),
//   result: lazy(() => import('views/quiz/QuizResult')),
// };
// const paths = {
//   list: lazy(() => import('views/paths/PathsList')),
//   detail: lazy(() => import('views/paths/PathsDetail')),
// };
const misc = {
  player: lazy(() => import('views/misc/Player')),
  material: lazy(() => import('views/misc/Material')),
  // syllabus: lazy(() => import('views/misc/Syllabus')),
};
const admin = {
  users: lazy(() => import('views/admin/Users')),
  subscriptions: lazy(() => import('views/admin/Subscriptions')),
  // payments: lazy(() => import('views/admin/Payments')),
  jsoneditor: lazy(() => import('views/admin/Jsoneditor')),
};
const profile =  {
  index: lazy(() => import('views/profile/Profile')),
  standard: lazy(() => import('views/profile/ProfileStandard')),
  settings: lazy(() => import('views/profile/ProfileSettings')),
};

const appRoot = DEFAULT_PATHS.APP.endsWith('/') ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length) : DEFAULT_PATHS.APP;

const routesAndMenuItems = {
  mainMenuItems: [
    {
      path: DEFAULT_PATHS.APP,
      exact: true,
      redirect: true,
      to: `${appRoot}/dashboards/elearning`,
      roles: [USER_ROLE.User, USER_ROLE.Instructor, USER_ROLE.Admin],
    },
    {
      path: `${appRoot}/dashboards`,
      icon: 'home-garage',
      label: 'menu.dashboards',
      exact: true,
      redirect: true,
      to: `${appRoot}/dashboards/elearning`,
      roles: [USER_ROLE.User, USER_ROLE.Instructor, USER_ROLE.Admin],
      subs: [
        { path: '/elearning', label: 'menu.elearning', component: dashboards.elearning },
        // { path: '/school', label: 'menu.school', component: dashboards.school },
        // { path: '/student', label: 'student', component: dashboards.student },
      ],
    },
    {
      path: `${appRoot}/courses`,
      label: 'menu.courses',
      icon: 'online-class',
      exact: true,
      redirect: true,
      to: `${appRoot}/courses/explore`,
      roles: [USER_ROLE.User, USER_ROLE.Instructor, USER_ROLE.Admin],
      subs: [
        { path: '/explore', label: 'menu.explore', component: courses.explore },
        // { path: '/list', label: 'menu', component: courses.list },
        { path: '/detail', label: '', component: courses.detail },
      ],
    },

    //   path: `${appRoot}/quiz`,
    //   label: 'menu.quiz',
    //   icon: 'quiz',
    //   exact: true,
    //   redirect: true,
    //   to: `${appRoot}/quiz/list`,
    //   subs: [
    //     { path: '/list', label: 'menu.list', component: quiz.list },
    //     { path: '/detail', label: 'menu.detail', component: quiz.detail },
    //     { path: '/result', label: 'menu.result', component: quiz.result },
    //   ],
    // },
    // {
    //   path: `${appRoot}/paths`,
    //   label: 'menu.paths',
    //   icon: 'destination',
    //   exact: true,
    //   redirect: true,
    //   to: `${appRoot}/paths/list`,
    //   subs: [
    //     { path: '/list', label: 'menu.list', component: paths.list },
    //     { path: '/detail', label: 'menu.detail', component: paths.detail },
    //   ],
    // },
    {
      path: `${appRoot}/misc`,
      label: 'menu.misc',
      icon: 'layout-5',
      // hideInMenu: true,
      exact: true,
      redirect: true,
      to: `${appRoot}/misc/player`,
      roles: [USER_ROLE.Instructor, USER_ROLE.Admin],
      subs: [
        { path: '/player', label: '', component: misc.player },
        { path: '/material', label: 'menu.material', component: misc.material },
        // { path: '/syllabus', label: 'menu.syllabus', component: misc.syllabus },
      ],
    },
    {
      path: `${appRoot}/admin`,
      label: 'menu.admin',
      icon: 'shield',
      // hideInMenu: true,
      exact: true,
      redirect: true,
      to: `${appRoot}/admin/users`,
      roles: [USER_ROLE.Admin, USER_ROLE.Instructor],
      subs: [
        {path: '/users', label: 'menu.users', component: admin.users},
        {path: '/subscriptions', label: 'menu.subscriptions', component: admin.subscriptions},
        // {path: '/payments', label: 'menu.payments', component: admin.payments},
        {path: '/jsoneditor', label: 'menu.jsoneditor', component: admin.jsoneditor},
      ],
    },
    {
      path: `${appRoot}/profile`,
      label: 'menu.profile',
      icon: 'gear',
      hideInMenu: true,
      exact: true,
      redirect: true,
      to: `${appRoot}/profile/standard`,
      roles: [USER_ROLE.User, USER_ROLE.Instructor, USER_ROLE.Admin],
      subs: [
        // { path: '/userProfile', label: 'menu.standard', component: profile.index },
        { path: '/standard', label: 'menu.standard', component: profile.standard },
        { path: '/settings', label: 'menu.settings', component: profile.settings },
      ],
    },
  ],
  sidebarItems: [],
};
export default routesAndMenuItems;
