import { createSlice,createAsyncThunk } from '@reduxjs/toolkit';
import { getAxiosConfig } from './authService';
import axios from 'axios';
const baseUrl = process.env.REACT_APP_BASE_URL;

const initialState = {
  isLogin: false,
  currentUser: {},
  token:'',
  courses: [],
  paths: [],
  quiz: [],
  tourStatus: 'not_played',
  hasLoggedInBefore: false,
};

export const updateTourStatus = createAsyncThunk(
  'auth/updateTourStatus',
  async(tourStatus,{getState}) => {
    const {auth } = getState();
    const response = await axios.put(`${baseUrl}/users/tour-status`,{tourStatus},
      getAxiosConfig(auth.token)
    );
    return response.data;
  }
);

export const updateUserLoginStatus = createAsyncThunk(
  'auth/updateUserLoginStatus',
  async (_, { getState }) => {
    const { auth } = getState();
    const response = await axios.put(
      `${baseUrl}/users/update-login-status`,
      { hasLoggedInBefore: true },
      getAxiosConfig(auth.token)
    );
    return response.data;
  }
);


const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCurrentUser(state, action) {
      // Use optional chaining and nullish coalescing for safer property access
      const profile = action.payload?.profile ?? {};
      const isLogin = action.payload?.isLogin ?? false;
      const courses = action.payload?.courses ?? [];
      const token = action.payload?.token ?? '';
      // Check user roleId and set the user role
      if (profile.roleId !== undefined) {
        profile.role = profile.roleId === 1 ? 'admin' : profile.roleId === 2 ? 'user' : 'instructor';
      }

      state.currentUser = {
        ...profile,
        thumb: profile.photos ?? '/img/profile/profile-11.webp',
      };
      state.isLogin = isLogin;
      state.courses = courses;
      state.token = token;
    },
    setCourses(state, action) {
      state.courses = action.payload;
    },
    setUserProgress(state, action) {
      const { subscriptionItemId, userProgress } = action.payload;
      const courseIndex = state.courses.findIndex(
        (course) => course.subscriptionItemId === subscriptionItemId
      );
      if (courseIndex !== -1) {
        state.courses[courseIndex].userProgress = userProgress;
      }
    },
    setTourStatus(state, action) {
      state.tourStatus = action.payload;
    },
    setHasLoggedInBefore(state, action) {
      state.currentUser.hasLoggedInBefore = action.payload.hasLoggedInBefore;
      state.hasLoggedInBefore = action.payload.hasLoggedInBefore;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(updateTourStatus.fulfilled, (state, action) => {
      state.tourStatus = action.payload;
    });
    builder.addCase(updateTourStatus.rejected, (state, action) => {
      console.error('Error updating tour status:', action.error);
    });
    builder.addCase(updateUserLoginStatus.fulfilled, (state, action) => {
      // state.currentUser.hasLoggedInBefore = true;
      state.hasLoggedInBefore = true;
    });
    builder.addCase(updateUserLoginStatus.rejected, (state, action) => {
      console.error('Error updating user login status:', action.error);
    });
  },
});

export const { setCurrentUser, setCourses, setUserProgress, setTourStatus, setHasLoggedInBefore } = authSlice.actions;
const authReducer = authSlice.reducer;

export default authReducer;